




























































































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: { FieldUser }
})
export default class component_name extends Vue {
  private dialog = false;
  private dialog1 = false;
  private datePopup = false;
  private message = "";
  private formReminder = {};
  @Prop()
  item!: any;
  public sendSMS(id: number) {
    this.$emit("sendSMS", id);
  }
  public setReminder(eventId: number) {
    this.$emit("setReminder", eventId);
  }
  public deleteUser(id: string) {
    this.$emit("deleteUser", id);
  }
}
