





















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import GeneralApi from "@/networks/GeneralApi";
import { Debounce } from "vue-debounce-decorator";
import DatetimePicker from "vue-persian-datetime-picker";
import MobileList from "@/views/components/pages/User/ListUserMobile.vue";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: {
    "datetime-picker": DatetimePicker,
    MobileList,
    FieldUser
  }
})
export default class Quick extends Vue {
  private data: any = [];
  private dialog = false;
  private dialog1 = false;
  private datePopup = false;
  private message = "";
  private form = {
    q: this.$route.query.search,
    order: null
  };
  private formReminder = {};
  private orderBy = [
    {
      text: "هیچکدام",
      value: null
    },
    {
      text: "تاریخ",
      value: "date"
    },
    // {
    //   text: "تعداد تماس",
    //   value: "count"
    // },
    {
      text: "تاریخ (نزولی)",
      value: "as-date"
    }
    // {
    //   text: "تعداد تماس (نزولی)",
    //   value: "as-count"
    // }
  ];
  private page = Number(this.$route.query.page || 1);
  mobile_list: any = [];

  mounted() {
    this.users();
    if (window.innerWidth < 600) {
      document.addEventListener("scroll", this.HandleScrollMobile);
    }
  }

  private timeout: any = null;

  HandleScrollMobile(e: any): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (
        e.target.scrollingElement.clientHeight +
          e.target.scrollingElement.scrollTop >=
        e.target.scrollingElement.scrollHeight - 100
      ) {
        this.page++;
      }
    }, 300);
  }

  get items() {
    if (this.mobile_list && this.mobile_list.length) {
      return this.mobile_list;
    } else {
      return this.data.data;
    }
  }

  public async setReminder(eventId: number): Promise<void> {
    try {
      const form = {
        ...this.formReminder,
        targetType: "event",
        targetId: eventId
      };
      const res = await new GeneralApi().setReminder(form);
      this.formReminder = {};
      this.dialog1 = false;
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطا در دریافت دسترسی ها",
        color: "danger"
      });
    }
  }

  public async users(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().list({
        ...this.form,
        roleId: [3],
        page: this.page
      });
      if (window.innerWidth < 600) {
        if (res.data.data.data && res.data.data.data.length) {
          this.mobile_list = [...this.mobile_list, ...res.data.data.data];
          this.data = res.data.data;
        } else {
          document.removeEventListener("scroll", this.HandleScrollMobile);
          this.$router.push({
            query: { page: String(this.page - 1), search: this.form.q }
          });
        }
      } else {
        this.data = res.data.data;
      }
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async deleteUser(id: string): Promise<void> {
    const confirm = window.confirm("آیا مطمئن به حذف کاربر هستید؟");
    if (!confirm) return;
    try {
      const res: AxiosResponse = await new UserApi().deleteUser(id);
      store.commit("showError", {
        message: res.data.message || "کاربر با موفقیت حذف شد",
        color: "danger"
      });
      this.users();
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async sendSMS(id: number): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().sendCustomMessage({
        message: this.message,
        targetId: id
      });
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.dialog = false;
      this.message = "";
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("page")
  public onPageChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.users();
  }

  @Watch("form.q")
  public onFormChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.fetchEntriesDebounced();
  }

  private _timerId = 0;

  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      this.users();
    }, 700);
  }

  @Watch("form.order")
  public onForm2Changed() {
    this.page = 1;
    this.users();
  }
}
