var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("#")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.item.id))])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("نام")]),_c('div',{staticClass:"value"},[_c('a',{attrs:{"title":'برای مشاهده کلیه رویدادهای ' +
          _vm.item.fname +
          ' ' +
          _vm.item.lname +
          ' کلیک کنید. ',"href":'/events?q=&type=all&userId=' + _vm.item.id}},[_vm._v(_vm._s(_vm.item.fname + " " + _vm.item.lname))])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("موبایل")]),_c('div',{staticClass:"value"},[_c('FieldUser',{attrs:{"user_id":_vm.item.id,"field":"mobile"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("ایمیل")]),_c('div',{staticClass:"value"},[_c('FieldUser',{attrs:{"user_id":_vm.item.id,"field":"email"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("تشکیل پرونده آنلاین")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("toDate")(_vm.item.created_at)))])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-caption"},[_vm._v("عملکرد")]),_c('div',{staticClass:"value"},[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{directives:[{name:"can",rawName:"v-can",value:('edit user'),expression:"'edit user'"}],attrs:{"href":'/users/' + _vm.item.id}},[_c('v-list-item-title',[_vm._v("ویرایش")])],1),_c('v-list-item',{directives:[{name:"can",rawName:"v-can",value:('edit user'),expression:"'edit user'"}],attrs:{"to":{ name: 'users-resume', params: { id: _vm.item.id } }}},[_c('v-list-item-title',[_vm._v("رزومه کاربر")])],1),_c('v-list-item',{directives:[{name:"can",rawName:"v-can",value:('delete user'),expression:"'delete user'"}],on:{"click":function($event){return _vm.deleteUser(_vm.item.id)}}},[_c('v-list-item-title',[_vm._v("حذف")])],1),_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission:any",value:('list event|full list event|own list event'),expression:"'list event|full list event|own list event'",arg:"any"}],attrs:{"href":'/events?userId=' + _vm.item.id}},[_c('v-list-item-title',[_vm._v("مشاهده رویدادها")])],1),_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission:any",value:('full list user|office list user'),expression:"'full list user|office list user'",arg:"any"}],attrs:{"href":'/profile-file?userId=' + _vm.item.id}},[_c('v-list-item-title',[_vm._v("پرونده")])],1),_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission:any",value:('full list user|office list user'),expression:"'full list user|office list user'",arg:"any"}],attrs:{"href":'/profile-file-detail?userId=' + _vm.item.id}},[_c('v-list-item-title',[_vm._v("جزئیات پرونده")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("ارسال پیامک مستقیم به کاربر ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("ارسال پیامک به کاربر")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"3","messages":"پیام های ارسالی برای بررسی ذخیره و بازبینی میشود","label":"متن پیامک"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("بستن ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.sendSMS(_vm.item.id)}}},[_vm._v("ارسال پیامک ")])],1)],1)],1),_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission:any",value:('set reminder'),expression:"'set reminder'",arg:"any"}]},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v("افزودن یادآوری برای این کاربر ")])]}}]),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("ایجاد یادآوری")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"2","label":"پیام","placeholder":"متن یادآوری را بنویسید","required":""},model:{value:(_vm.formReminder.message),callback:function ($$v) {_vm.$set(_vm.formReminder, "message", $$v)},expression:"formReminder.message"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"تاریخ یادآوری","id":"datetime-picker"},model:{value:(_vm.formReminder.target_date),callback:function ($$v) {_vm.$set(_vm.formReminder, "target_date", $$v)},expression:"formReminder.target_date"}}),_c('datetime-picker',{attrs:{"locale":"fa","show":_vm.datePopup,"type":"date","format":"jYYYY/jMM/jDD","element":"datetime-picker"},model:{value:(_vm.formReminder.target_date),callback:function ($$v) {_vm.$set(_vm.formReminder, "target_date", $$v)},expression:"formReminder.target_date"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v("بستن ")]),_c('v-btn',{attrs:{"color":"success darken-1"},on:{"click":function($event){return _vm.setReminder(_vm.item.id)}}},[_vm._v("ذخیره ")])],1)],1)],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }